<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <!-- logo -->
        <v-card-title class="d-flex align-center justify-center py-7">
          <a href="javascript:void(0);">
            <v-img
                  class="logo me-3 d-inline-block"
                  max-height="30px"
                max-width="30px"
                alt="logo"
                contain
                  src="@/assets/images/all/logo.svg"
                ></v-img>

            <h2 class="text-2xl font-weight-semibold d-inline-block">
              台中市政府環保局
            </h2>
          </a>
        </v-card-title>

        <!-- title -->
        <v-card-text>
          <p class="mb-2">
            請輸入公司統編、帳號、密碼
          </p>
        </v-card-text>

        <!-- login form -->
        <v-card-text>
          <v-form>
            <div class="mb-3">
              <v-text-field
                v-model="cnum"
                outlined
                label="* 公司統編"
                placeholder="公司統編"
                hide-details
              ></v-text-field>
              <div class="chk required txt-hint" v-if="validation.cnum.is_req">必填</div>
            </div>
            <div class="mb-3">
              <v-text-field
                v-model="email"
                outlined
                label="* 帳號"
                placeholder="john@example.com"
                hide-details
              ></v-text-field>
              <div class="chk format txt-hint" v-if="validation.email.is_format">email 格式不正確</div>
              <div class="chk required txt-hint" v-if="validation.email.is_req">必填</div>
            </div>
            <div>
              <v-text-field
                v-model="password"
                outlined
                :type="isPasswordVisible ? 'text' : 'password'"
                label="* 密碼"
                placeholder="············"
                :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                hide-details
                @click:append="isPasswordVisible = !isPasswordVisible"
              ></v-text-field>
              <div class="chk required txt-hint" v-if="validation.password.is_req">必填</div>
            </div>
            <!--<div class="d-flex align-center justify-space-between flex-wrap">
              <v-checkbox
                label="Remember Me"
                hide-details
                class="me-3 mt-1"
              >
              </v-checkbox>

               forgot link -->
              <!--<a
                href="javascript:void(0)"
                class="mt-1"
              >
                Forgot Password?
              </a>
            </div>-->

            <v-btn
              block
              color="primary"
              style="background: #2d7339 !important;"
              class="mt-6"
              @click="login()"
            >
              登入
            </v-btn>
          </v-form>
        </v-card-text>

      </v-card>
    </div>

    
    <div class="bg-deco-area">
      <div class="building-area">
        <div class="bdeco bd-item bu01">
          <v-img src="@/assets/images/login/bottom_building_001.svg"></v-img>
        </div>
        <div class="bdeco bd-item bu02">
          <v-img src="@/assets/images/login/bottom_building_002.svg"></v-img>
        </div>
      </div>
      <div class="tree-area">
        <div class="bdeco bd-item tre01">
          <v-img src="@/assets/images/login/bottom_tree_001.svg"></v-img>
        </div>
        <div class="bdeco bd-item tre02">
          <v-img src="@/assets/images/login/bottom_tree_002.svg"></v-img>
        </div>
        <div class="bdeco bd-item tre03">
          <v-img src="@/assets/images/login/bottom_tree_003.svg"></v-img>
        </div>
        <div class="bdeco bd-item tre04">
          <v-img src="@/assets/images/login/bottom_tree_004.svg"></v-img>
        </div>
        <div class="bdeco bd-item tre05">
          <v-img src="@/assets/images/login/bottom_tree_005.svg"></v-img>
        </div>
      </div>
      <div class="people-area">
        <div class="bdeco bd-item peo01">
          <div class="people">
            <v-img src="@/assets/images/login/bottom_people_001.svg"></v-img>
          </div>
          <div class="dialog">
            <v-img src="@/assets/images/login/bottom_people_001_1.svg"></v-img>
          </div>
        </div>
        <div class="bdeco bd-item peo02">
          <v-img src="@/assets/images/login/bottom_people_002.svg"></v-img>
        </div>
        <div class="bdeco bd-item peo03">
          <v-img src="@/assets/images/login/bottom_people_003.svg"></v-img>
        </div>
      </div>
      <div class="cloud-area">
        <div class="bdeco bd-item clo01">
          <div class="cloud">
            <v-img src="@/assets/images/login/bottom_cloud_001.svg"></v-img>
          </div>
          <div class="rain">
            <v-img src="@/assets/images/login/bottom_cloud_002.svg"></v-img>
          </div>
        </div>
      </div>
      <div class="flower-area">
        <div class="bdeco bd-item flo01">
          <v-img src="@/assets/images/login/bottom_flower_001.svg"></v-img>
        </div>
        <div class="bdeco bd-item flo02">
          <v-img src="@/assets/images/login/bottom_flower_001.svg"></v-img>
        </div>
        <div class="bdeco bd-item flo03">
          <v-img src="@/assets/images/login/bottom_flower_002.svg"></v-img>
        </div>
      </div>
      <div class="wind-area">
        <div class="bdeco bd-item wind01">
          <v-img src="@/assets/images/login/bottom_windpower_001.svg"></v-img>
        </div>
      </div>
      <div class="tube-area">
        <div class="bdeco bd-item tub01">
          <div class="tube">
            <v-img src="@/assets/images/login/bottom_tube_001.svg"></v-img>
          </div>  
          <div class="smoke">
            <v-img src="@/assets/images/login/bottom_tube_001_1.svg"></v-img>
          </div>  
        </div>
      </div>
      <div class="land-area"></div>
      <div class="cow-area">
        <div class="bdeco bd-item cow01">
          <v-img src="@/assets/images/login/bottom_cow_001.svg"></v-img>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiFacebook, mdiTwitter, mdiGithub, mdiGoogle, mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'
import config from '@/config.js';
import axios from 'axios';
export default {
  setup() {
    const isPasswordVisible = ref(false)
    const email = ref('')
    const password = ref('')
    const socialLink = [
      {
        icon: mdiFacebook,
        color: '#4267b2',
        colorInDark: '#4267b2',
      },
      {
        icon: mdiTwitter,
        color: '#1da1f2',
        colorInDark: '#1da1f2',
      },
      {
        icon: mdiGithub,
        color: '#272727',
        colorInDark: '#fff',
      },
      {
        icon: mdiGoogle,
        color: '#db4437',
        colorInDark: '#db4437',
      },
    ]

    return {
      isPasswordVisible,
      email,
      password,
      socialLink,

      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    }
  },
 data: () => ({
     /*u_id:"",
    u_pass:"",*/
    chk_num:"",
    cnum:"",
    validation:{
      email:{
        is_req:false,
        is_format:false
      },
      cnum:{
        is_req:false,
      },
      password:{
        is_req:false,
      }
    }
  }),
  mounted() {
    $(function () {
      $("body").attr("class","login");
    });
  },
  methods:{
    login(){

      
    if(this.chkReq()){
        let parseData = {
            cnum: this.cnum,
            u_id: this.email,
            u_password: this.password,
            chk_num: this.chk_num,
          };
          
          const apiurl=config.geturl(config.API.memallExe,{});
          const apiurl_authUrl = config.geturl(config.API.exeAuthUrl,{});
          const memlogin_url = config.geturl(config.API.memLogin,{});
          const webroot_url = config.geturl(config.API.webRootUrl,{});
          let redirect_url="";
          axios.post(apiurl, parseData)
                .then((response) => {
                  console.log(response.data);
                  if(response.data.isOk){
                    //檢查權限
                    axios.get(apiurl_authUrl).then(response => {
                        //console.log(response.data);
                        if(response.data.isOk){
                          if(response.data.log != "")
                              redirect_url = webroot_url + response.data.log;                            
                              //redirect_url = "http://localhost:8080/" + response.data.log;
                        }
                        if(redirect_url != ""){
                          location.href=redirect_url;
                        }else{
                          alert("您沒有瀏覽網頁的權限");
                        }
                    });
                  }else{
                    alert(response.data.log);
                  }
              })
    }

      
    },
    chkReq(){
        let success=false;
        if(this.cnum == "" || this.cnum == null){
          this.validation.cnum.is_req = true;
        }
        if(this.email == "" || this.email == null){
          this.validation.email.is_req = true;
          if(!isEmail(this.email)){
            this.validation.email.is_format = true;
          }
        }
        if(this.password == "" || this.password == null){
          this.validation.password.is_req = true;
        }
        return success = (this.validation.cnum.is_req || this.validation.email.is_req || this.validation.email.is_format || this.validation.password.is_req)?false:true;
    }
  },
  watch:{
    email:function(newValue,oldValue){
        this.validation.email.is_req = (newValue != "" && newValue != null)?false:true;
        this.validation.email.is_format = (isEmail(newValue))?false:true;
    },
    cnum:function(newValue,oldValue){
        this.validation.cnum.is_req = (newValue != "" && newValue != null)?false:true;
    },
    password:function(newValue,oldValue){
        this.validation.password.is_req = (newValue != "" && newValue != null)?false:true;
    },
  }
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
